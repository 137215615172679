<template>
  <van-popup :value="value" @input="close" class="login">
    <div class="top">{{ title }}</div>
    <van-form @submit="onSubmit" class="form" v-if="type == 'password'">
      <van-field v-model="username" placeholder="请输入手机号">
        <template #left-icon>
          <img
            src="../assets/user.png"
            slot="left-icon"
            style="width:60%;height:70%;padding-top:2px"
          />
        </template>
      </van-field>
      <van-field v-model="password" type="password" placeholder="请输入密码">
        <template #left-icon>
          <img
            src="../assets/password.png"
            slot="left-icon"
            style="width:60%;height:70%;padding-top:2px"
          />
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit"
          >登录</van-button
        >
      </div>
    </van-form>
    <van-form @submit="onSubmitSMS" class="form" v-if="type == 'sms'">
      <van-field v-model="username" placeholder="请输入手机号">
        <template #left-icon>
          <img
            src="../assets/user.png"
            slot="left-icon"
            style="width:60%;height:70%;padding-top:2px"
          />
        </template>
      </van-field>
      <van-field v-model="sms" type="password" placeholder="请输入验证码">
        <template #left-icon>
          <img
            src="../assets/safe.png"
            slot="left-icon"
            style="width:60%;height:60%;padding-top:6px"
          />
        </template>
        <template #button>
          <van-button
            @click="getsms"
            size="small"
            type="primary"
            style="height:30px;background-color: #1989fa;border: 1px solid #1989fa"
          >
            {{ message }}
          </van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit"
          >登录</van-button
        >
      </div>
    </van-form>
    <div class="hhh">
      <van-button class="bottom" @click="change" v-if="type == 'password'" round block type="info" style="width:45%">
        验证码登录
      </van-button>
      <van-button class="bottom" @click="change" v-if="type == 'sms'" round block type="info"  style="width:45%">密码登录</van-button>
      <van-button @click="close" round block type="info"  style="width:45%"> 暂不登录</van-button>
    </div>
  </van-popup>
</template>

<script>
import { passWordLogin, codeLogin, getCode } from "@/service/login_api.js";
import { Toast } from "vant";
import { setById1 } from "@/service/exam.js";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/baseDocument.js";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      type: "password",
      username: "",
      password: "",
      sms: "",
      message: "发送验证码",
    };
  },
  created() {
    this.getById1();
  },
  methods: {
    async getById1() {
      //像接口头部传用户信息
      let arrays = await getAdmins( this.$store.state.memberIdDocument,this.$route.query.channel);  
      await setById1(arrays);
    },
    close() {
      let flag = false;
      this.$emit("input", flag);
    },
    async onSubmit() {
      let data = {
        tel: this.username,
        password: this.password,
      };
      const res = await passWordLogin(data);
      if (res.code == 0) {
        Toast.success("登录成功");
        this.close();

        let query = Object.assign(
          { memberId: res.data.id },
          this.$route.query
        );

        this.$router.replace({ path: this.$route.path, query: query });
        this.$store.commit("memberIdDocument", res.data.id);
        // this.getById();
    
        
      } else {
        Toast.fail(res.message);
      }
    },
    async getsms() {
      if (this.username == "") {
        Toast.fail("请输入手机号");
        return;
      }
      var Reg = /^[1][34578][0-9]{9}$/;
      if (Reg.test(this.username)) {
      
        const res = await getCode(this.username,'yunicu');
        console.log(res);

        if (res.code == 0) {
          Toast.success("发送成功");
          this.countdown();
        } else {
          Toast.fail(res.message);
        }
      } else {
        Toast.fail("手机号码格式错误！");
      }
    },
    async onSubmitSMS() {
      let data = {
        tel: this.username,
        sms: this.sms,
      };
      const res = await codeLogin('yunicu',data);
      if (res.code == 0) {
        Toast.success("登录成功");
        this.close();
        let query = Object.assign(
          { memberId: res.data.id },
          this.$route.query
        );
        this.$router.replace({ path: this.$route.path, query: query });
        this.$store.commit("memberIdDocument", res.data.id);
        //  this.getById();
      } else {
        Toast.fail(res.message);
      }
    },
    change() {
      this.type = this.type == "sms" ? "password" : "sms";
    },
    // 60s倒计时
    countdown() {
      let count = 60;
      this.message = `60s`;
      let timer = setInterval(() => {
        if (count > 1) {
          count--;
          this.message = `${count}s`;
        } else {
          this.message = `重新发送`;
          clearInterval(timer);
        }
      }, 1000);
    },
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberId,
        this.$route.query.channel //渠道
      );
      await setById(arrays);
    },
  },
};
</script>

<style scoped>
.van-popup {
  width: 300px;
  height: 350px;
  border-radius: 20px;
}
.login .form {
  padding: 20px;
}

.login .van-cell {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid rgba(170, 161, 161, 0.225);
  padding: 10px 10px;
}
.login .top {
  margin-left: 25px;
  margin-top: 20px;
}
.login .bottom {
  /* margin-left: 25px; */
}
.hhh {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size:12px;
}
</style>
