import axios from "axios";
import qs from "qs";
// import { isAndroidOrIOS } from "@/utils/os";

const header = "https://yunicu-api.yunicu.com"; //外包正式环境
// const header = 'http://yunicu-api.we-fire.cn'  //外包测试环境
 const headerYUN = "https://application.yunicu.com"; //更换外外包
const ERR_OK = 0;
let admins;
// let os = isAndroidOrIOS();


export function setById1(data) {
  admins = qs.parse(data);
  console.log("333",admins);
  
}

export function get(url, params) {
  return axios
    .get(header + url, {
      params,
    })
    .then((res) => {
      const serverData = res.data;
      if (serverData.code === ERR_OK) {
        return serverData;
      }
    })
    .catch((e) => {
      console.log(e);
    });
}
//修改直播间观看人数
export function post(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        "set-room-count":
          "5xVb7hlhjGcdkKpMXgicAVgx8syK5VJz193k0i/0sLP6DzE6lRrU1K3B",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postToken(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        "x-token":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjkiLCJuYW1lIjoi5pu55oWn54-NIn0.21L7QKLUsBlcnNusi-8fO6U2AbM3-QDISBy-2HMuu8s",
        "x-client-tag": params.channel,
        member_id: params.member_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postLogin(url, params) {
  return axios
    .post(headerYUN + url, params, { headers: { channel: admins.channel,os:admins.os } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
