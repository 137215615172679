import { postLogin } from "./exam.js";
// 更换本地
// 密码登录
export function passWordLogin(data) {
  return postLogin("/user/login/password", data);
}

export function codeLogin(type,data) {
  return postLogin(`/user/sms/login?type=${type}`, data);
}
// 获取验证码
export function getCode(tel,type) {
  return postLogin(`/user/sms/login/send?tel=${tel}&type=${type}`);
}
